import { Toast } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'
import { promotionCenter } from 'public/src/pages/common/promotion'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { stringifyQueryString } from '@shein/common-function'

const SPECIAL_FEATURE_ID = 1000719

// 是否命中特殊属性
export function matchSpecialFeatures(attrId) {
  return [attrId]?.includes?.(SPECIAL_FEATURE_ID)
}
// 商详属性内容是否包含Special Features
export function contentIncludeSpecialFeatures(attrSet) {
  if (!Array.isArray(attrSet) || !attrSet.length) {
    return false
  }

  const hit = attrSet.some(item => {
    if (matchSpecialFeatures(+item?.attr_id)) {
      return true
    }
    if (!Array.isArray(item) || !item.length) {
      return false
    }
    return matchSpecialFeatures(+item?.[0]?.attr_id)
  })
  return !!hit
}

// 仅在唤起的加车弹窗为非主商品时，加车成功才会触发这个自动领到手价最优券的方法
export async function autoGetCouponAtAddBagNotMain (estimatedInfo, language, needToast, cb) {
  if (!isLogin()) return
  if (!estimatedInfo) return
  const getEstimatedCoupon = () => {
    if (!estimatedInfo) return
    if (!estimatedInfo?.optimalCouponList?.length) return
    const { EstimatedPrice } = estimatedInfo
    if (!(EstimatedPrice?.p?.E_Price_Cal === 'Non_Threshold' || EstimatedPrice?.p?.E_Price_Cal === 'Have_Threshold')) return []
    const couponCodes = estimatedInfo?.optimalCouponList?.filter(coupon => {
      const isBindCoupon = coupon?.is_bind === 1 // 是绑定券。是否是绑定类型,0-否，1-是
      const notReceived = coupon?.bind_status != 1 // 未领取。 1已领取 2未领取
      return isBindCoupon && notReceived
    })?.map(coupon => coupon?.coupon_code) || []
    return couponCodes
  }
  let estimatedCouponCodes = getEstimatedCoupon() || []
  if (!estimatedCouponCodes.length) return
  schttp({
    method: 'POST',
    url: '/api/productInfo/bindCoupon/post',
    data: {
      coupon_codes: estimatedCouponCodes
    },
  }).then(res => {
    const failure = !res || res.code != 0 || res.info?.failureList?.find?.(item => estimatedCouponCodes.includes(item.couponCode))
    if (failure) {
      cb && cb() 
    } else {
      setTimeout(() => {
        needToast && Toast({
          content: language.SHEIN_KEY_PWA_25104 || 'We automatically help you to claim coupons',
          iconClass: 'suiiconfont sui_icon_coupon_discount_16px',
        })
        cb && cb()
      }, 1000) // 延迟，等加车弹窗的提示结束了，再提示领券成功
    }
    const abtest = abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
    daEventCenter.triggerNotice({
      daId: '1-6-4-42',
      extraData: {
        coupon_id: estimatedCouponCodes.join(`,`),
        status: failure ? 0 : 1,
        abtest
      }
    })
  })
}

/**
 * @description 获取parentCats中所有catId
 * @param {*} parentCats 
 * @returns {string[]}
 */
export function getAllCatIdsFromParentsTree (parentCats = {}) {
  if (!parentCats?.cat_id) return [] 
  let result = [parentCats?.cat_id]
  if (parentCats?.children?.length) {
    const recursion = (arr) => {
      arr.forEach(item => {
        if (item && item.cat_id) {
          result.push(item.cat_id)
        }
        if (item.children?.length) {
          recursion(item.children)
        }
      })
    }
    recursion(parentCats.children)
  }
  return result
}

/**
 * @description 获取parentCats末级品类名称(多语言)
 * @param {*} parentCats 
 * @returns {string}
 */
export const getCategoryName = (parentCats) => {
  if (typeof window === 'undefined' || !parentCats) return ''
  let catName = ''
  const filterCategoryName = (parentCats) => {
    if (parentCats.multi && parentCats.multi.cat_name) {
      catName = parentCats.multi.cat_name
    } else if (parentCats.cat_name) {
      catName = parentCats.cat_name
    }
    if (parentCats.children && parentCats.children.length) {
      return filterCategoryName(parentCats.children[0])
    } else {
      return catName
    }
  }
  return filterCategoryName(parentCats)
}

// 商详用-新商卡客户端预处理
export async function getDetailRecPreTreat(params) {
  const { products, itemConfig } = params
  const language = await promotionCenter.getLanguage()

  // 防止请求体过大
  const _products = products.reduce((list, item) => {
    if (!item.promotionInfo?.length) return list
    list.push({
      promotionInfo: item.promotionInfo?.map((info) => ({ id: info.id, typeId: info.typeId })),
    })
    return list
  }, [])
  // 实时获取促销信息
  let promotionInfoFromServer = {}
  if (_products.length) {
    promotionInfoFromServer = await schttp({
      method: 'POST',
      url: '/api/productInfo/postIntegrityPromotionInfo/get',
      data: {
        asyncPromotionIds: PROMOTION_CONFIG.FetchPromotionLableTypeIds.join(),
        fetchedPromotionIds: Object.keys(window.promotionCenter?.cache.integrityPromotionInfo || {}).join(),
        products: _products,
      }
    })
  }

  const res = dealProductsPretreatInfo({
    products, 
    itemConfig, 
    promotionInfoFromServer, 
    language,
  })
  return res
}

/**
 * 榜单跳转链接
 */
export function getRankingLink(options = {}) {
  if (!isHasRankingLink(options)) return ''

  if (typeof window === 'undefined') return ''
  const { contentCarrierId, itemInfoId, goods_id, carrierTempId, title, carrierSubType, cateIds, composeGoodsId, tab_page_id, ici = '', score = 1 } = options
  const { langPath } = gbCommonInfo

  if (!title) return ''
  const params = {
    page_from: 'detail',
    contentCarrierId,
    item_id: itemInfoId,
    page_title: title,
    rankTypeText: encodeURIComponent(title),
    templateId: carrierTempId,
    currentItem: goods_id,
    goods_id: goods_id,
    ici: encodeURIComponent(ici),
    scene_id: 52,
    scroll_pos: Number(score)
  }

  const subTypeStr = String(carrierSubType)
  // 通过商详榜单推荐进入时记录为：si={$.推荐场景ID，如：52}`cn={$.组件名称，没有报-}`ci={$.组货ID}`ri={$.榜单类型ID，如热销15}`ps={$.资源位位置，没有报-}`jc={榜单内容体id}；
  const _srcIdentifierList = [`si=${52}`, `cn=-`, `ci=${composeGoodsId || '-'}`, `ri=${subTypeStr}`, `ps=-`, `jc=${contentCarrierId}`]
  const src_identifier = _srcIdentifierList.join('`')

  const queryObj = Object.assign({}, params, {
    carrierSubType: subTypeStr,
    cateIds: cateIds || '',
    compose_id: composeGoodsId,
    src_module: 'recommend', // 商详为 recommend·
    src_tab_page_id: tab_page_id,
    src_identifier,
  })

  if (['15', '75'].includes(subTypeStr)) {
    return `${langPath}/ranking_list/page?${stringifyQueryString({
      queryObj,
    })}`
  }
  return ''
}

/**
 * 是否存在榜单跳转链接(UI层有个箭头展示)
 */
export function isHasRankingLink({ title, carrierSubType } = {}) {
  if (!title) return false
  const subTypeStr = String(carrierSubType)
  if (['15', '75'].includes(subTypeStr)) return true

  return false
}
